import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
		<path d="M1330 3560 c0 -5 15 -10 34 -10 19 0 44 -9 57 -19 24 -19 24 -21 27
-251 2 -127 1 -241 -2 -254 -3 -14 -29 -37 -67 -61 -79 -49 -189 -152 -245
-231 -55 -78 -116 -207 -140 -299 -26 -98 -26 -323 0 -419 45 -167 126 -306
245 -418 175 -166 358 -238 601 -238 228 0 394 64 600 233 185 151 320 303
505 569 l29 42 16 -37 c8 -21 21 -36 28 -35 8 2 47 90 87 197 60 160 84 210
136 285 86 124 163 219 204 251 l35 26 0 -349 c0 -393 4 -375 -78 -388 -23 -4
-42 -10 -42 -14 0 -12 387 -21 437 -9 115 25 233 121 288 233 125 255 -42 565
-325 605 -36 5 -68 7 -72 4 -5 -2 -8 80 -8 182 0 184 0 186 25 210 17 17 35
25 60 25 25 0 35 4 33 13 -3 6 -12 11 -21 11 -9 -1 -79 -2 -155 -3 l-139 -1
-163 -437 c-138 -370 -213 -545 -225 -531 -1 2 -87 219 -190 483 -103 264
-188 481 -190 483 -1 2 -75 4 -164 5 -113 1 -161 -1 -161 -9 0 -6 21 -14 46
-17 26 -2 57 -13 71 -23 l24 -19 -1 -575 c-1 -645 4 -604 -76 -616 -85 -12
-42 -24 91 -24 134 0 176 12 90 25 -79 11 -76 -18 -73 586 l3 534 194 -505
c107 -278 196 -513 199 -523 4 -18 -53 -108 -159 -247 -231 -306 -517 -534
-744 -595 -85 -23 -258 -30 -352 -15 -216 35 -425 163 -549 336 -56 78 -116
208 -140 299 -26 102 -24 313 4 416 39 142 131 298 233 395 57 53 171 134 188
134 14 0 15 -437 1 -494 -10 -45 -20 -54 -72 -62 -88 -15 -24 -24 167 -24 191
0 255 9 168 24 -54 9 -64 18 -74 67 -13 67 -11 406 2 432 14 27 50 67 60 67 4
0 76 -100 160 -222 190 -278 217 -310 281 -342 47 -23 64 -26 165 -26 112 0
149 12 75 25 -76 12 -84 22 -286 318 -101 147 -132 186 -145 181 -31 -14 -102
-16 -131 -4 -17 7 -46 30 -65 51 l-35 40 27 34 c36 45 56 47 41 4 -22 -65 58
-115 108 -68 47 45 18 108 -55 118 l-42 6 176 197 c96 108 190 209 209 223 24
18 52 28 90 32 107 11 55 23 -99 23 -97 0 -155 -4 -155 -10 0 -5 19 -10 43
-10 54 0 84 -19 70 -45 -6 -10 -90 -107 -187 -215 -171 -191 -178 -198 -221
-204 -25 -3 -57 -9 -71 -12 l-26 -7 4 223 c4 218 4 223 27 241 13 11 38 19 57
19 19 0 34 5 34 10 0 6 -75 10 -205 10 -130 0 -205 -4 -205 -10z m2134 -653
c-38 -22 -95 -82 -165 -176 -40 -55 -75 -98 -76 -97 -2 1 54 156 125 343 l127
340 3 -200 c2 -179 1 -202 -14 -210z m-1746 144 c-3 -10 -87 -101 -92 -101 -2
0 -3 21 -1 47 1 41 5 48 26 54 36 10 71 10 67 0z m2154 -138 c247 -116 307
-436 122 -642 -89 -99 -249 -149 -295 -92 -18 22 -19 47 -19 398 l0 375 63 -4
c42 -2 83 -14 129 -35z"/>
<path d="M1120 1880 c0 -56 3 -70 15 -70 10 0 15 10 15 28 l1 27 20 -27 c11
-16 30 -28 41 -28 19 0 19 2 -7 36 -27 35 -27 36 -8 57 33 36 36 47 15 47 -12
0 -29 -14 -41 -32 l-21 -33 0 33 c0 22 -5 32 -15 32 -12 0 -15 -14 -15 -70z"/>
<path d="M1387 1944 c-4 -4 -7 -36 -7 -71 0 -55 2 -63 19 -63 13 0 18 7 17 23
-1 16 5 23 22 25 18 3 22 -1 22 -22 0 -16 6 -26 15 -26 12 0 15 14 15 70 0 56
-3 70 -15 70 -10 0 -15 -10 -15 -30 0 -23 -4 -30 -20 -30 -11 0 -21 7 -21 15
-3 35 -4 38 -15 42 -5 2 -13 0 -17 -3z"/>
<path d="M1676 1885 c-14 -36 -26 -67 -26 -70 0 -12 28 -3 34 10 3 8 16 15 30
15 13 0 26 -7 30 -15 3 -8 13 -15 22 -15 11 0 14 5 9 18 -4 9 -16 41 -27 70
-12 28 -26 52 -33 52 -7 0 -25 -29 -39 -65z m54 -17 c0 -5 -7 -8 -15 -8 -15 0
-20 21 -8 33 7 7 23 -11 23 -25z"/>
<path d="M1940 1881 c0 -57 3 -71 15 -71 13 0 15 12 13 67 -2 42 -8 68 -15 71
-10 3 -13 -15 -13 -67z"/>
<path d="M2137 1943 c-4 -3 -7 -35 -7 -70 0 -56 2 -64 18 -61 9 2 16 10 15 16
-4 17 17 15 23 -3 4 -8 15 -15 26 -15 19 0 19 2 5 23 -13 20 -13 26 -1 42 18
25 18 31 -6 55 -19 19 -60 27 -73 13z m59 -34 c3 -6 1 -16 -5 -22 -9 -9 -14
-8 -21 3 -5 8 -10 18 -10 23 0 11 28 8 36 -4z"/>
<path d="M2380 1943 c0 -3 11 -21 25 -39 14 -18 25 -46 25 -63 0 -21 5 -31 15
-31 10 0 15 10 15 33 0 17 9 46 20 62 24 36 25 45 5 45 -8 0 -20 -12 -28 -26
l-14 -26 -16 26 c-16 23 -47 36 -47 19z"/>
<path d="M2741 1723 c-14 -73 -14 -83 3 -83 9 0 18 16 24 43 l9 42 12 -43 c15
-54 39 -53 56 3 l12 40 8 -42 c5 -31 12 -43 25 -43 16 0 17 5 4 67 -8 37 -16
69 -19 71 -12 12 -33 -11 -45 -49 l-13 -44 -15 48 c-22 68 -46 64 -61 -10z"/>
<path d="M3089 1733 c-41 -93 -40 -93 -19 -93 11 0 20 7 20 15 0 9 9 15 25 15
14 0 28 -7 31 -15 4 -8 14 -15 25 -15 17 0 16 5 -11 67 -15 37 -33 69 -39 71
-6 2 -20 -18 -32 -45z m41 -29 c0 -8 -6 -14 -14 -14 -9 0 -12 7 -9 20 6 22 23
18 23 -6z"/>
<path d="M3365 1755 c-31 -30 -32 -65 -2 -93 30 -28 68 -28 95 1 31 33 28 47
-13 47 -19 0 -35 -4 -35 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -15 -46
-12 -60 5 -29 35 12 78 54 55 35 -18 47 -3 17 21 -31 26 -57 24 -86 -6z"/>
<path d="M3669 1733 c-40 -93 -40 -93 -19 -93 11 0 20 7 20 15 0 9 9 15 25 15
14 0 28 -7 31 -15 10 -26 37 -18 30 8 -10 42 -47 117 -57 117 -5 0 -19 -21
-30 -47z m41 -29 c0 -8 -6 -14 -14 -14 -9 0 -12 7 -9 20 6 22 23 18 23 -6z"/>
<path d="M3935 1764 c-24 -24 -14 -47 28 -67 21 -10 35 -22 32 -27 -9 -15 -26
-12 -47 7 -22 19 -37 9 -22 -15 7 -11 24 -17 49 -17 70 0 75 60 7 77 -18 5
-32 13 -32 18 0 13 37 13 45 0 9 -15 25 -12 25 5 0 16 -30 35 -54 35 -8 0 -22
-7 -31 -16z"/>
<path d="M4190 1710 c0 -56 3 -70 15 -70 9 0 15 9 15 25 0 18 5 25 20 25 15 0
20 -7 20 -25 0 -19 5 -25 20 -25 19 0 21 5 18 68 -2 52 -6 67 -18 67 -9 0 -16
-11 -18 -27 -2 -20 -9 -28 -23 -28 -14 0 -19 7 -19 30 0 20 -5 30 -15 30 -12
0 -15 -14 -15 -70z"/>
<path d="M4460 1710 c0 -56 3 -70 15 -70 12 0 15 14 15 70 0 56 -3 70 -15 70
-12 0 -15 -14 -15 -70z"/>
<path d="M3160 1410 c0 -68 1 -70 26 -70 56 0 89 42 72 91 -9 27 -44 49 -78
49 -18 0 -20 -7 -20 -70z m60 26 c16 -20 9 -60 -12 -64 -15 -3 -18 4 -18 37 0
43 9 51 30 27z"/>
<path d="M3446 1415 c-14 -36 -26 -67 -26 -70 0 -12 28 -3 34 10 3 8 16 15 30
15 13 0 26 -7 30 -15 3 -8 13 -15 22 -15 11 0 14 5 9 18 -4 9 -16 41 -27 70
-12 28 -26 52 -33 52 -7 0 -25 -29 -39 -65z m54 -17 c0 -5 -7 -8 -15 -8 -17 0
-18 2 -9 25 5 13 8 14 15 3 5 -7 9 -16 9 -20z"/>
<path d="M3700 1465 c0 -8 7 -15 15 -15 12 0 15 -13 15 -56 0 -47 3 -55 18
-52 13 2 18 15 20 56 2 37 7 52 18 52 7 0 14 7 14 15 0 11 -12 15 -50 15 -38
0 -50 -4 -50 -15z"/>
<path d="M3982 1428 c-11 -29 -23 -61 -27 -70 -5 -13 -2 -18 9 -18 9 0 19 7
22 15 4 8 17 15 30 15 14 0 27 -7 30 -15 6 -13 34 -22 34 -10 0 21 -55 135
-65 135 -7 0 -21 -24 -33 -52z m48 -30 c0 -5 -7 -8 -15 -8 -17 0 -18 2 -9 25
5 13 8 14 15 3 5 -7 9 -16 9 -20z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
